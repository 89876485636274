import React, {Component} from 'react';
import RegistrationForm from "./RegistrationForm";
import RecoverForm from "./RecoverForm";
import LoginForm from "./LoginForm";
import Logo from "../svg/Logo";
import axios from "axios";
import ChangePasswordForm from "./ChangePasswordForm";

const Screens = Object.freeze({
    SIGNUP: Symbol("SIGNUP"),
    LOGIN: Symbol("LOGIN"),
    RECOVER: Symbol("RECOVER"),
    CHANGEPW: Symbol("CHANGEPW"),
    SUCCESS: Symbol("SUCCESS"),
});

class AuthFrame extends Component {
    constructor(props) {
        super(props);
        this.onRegistrationSubmit = this.onRegistrationSubmit.bind(this);
        this.onLoginSubmit = this.onLoginSubmit.bind(this);
        this.onRecoverSubmit = this.onRecoverSubmit.bind(this);
        this.openRecoveryForm = this.openRecoveryForm.bind(this);
        this.openLoginForm = this.openLoginForm.bind(this);
        this.openSignupForm = this.openSignupForm.bind(this);
        this.onChangePasswordSubmit = this.onChangePasswordSubmit.bind(this);
        if (this.props.recoveryToken) {
            this.state = {
                screen: Screens.CHANGEPW,
                error: ''
            }
        } else {
            this.state = {
                screen: Screens.LOGIN,
                error: ''
            }
        }
    }

    onRegistrationSubmit(values, setSubmitting) {
        axios
            .post('/api/v1/user', values)
            .then(result => {
                setSubmitting(false);
                this.setState({
                    screen: Screens.SUCCESS,
                    success: {
                        title: "Verifique o seu email",
                        message: "Por favor verifique o seu email para concluir o processo de registo e ativar a sua conta.",
                        error: ''
                    }
                })
            })
            .catch((err) => {
                setSubmitting(false);
                this.setState({error: `Ocorreu um erro: ${err.response.data}`})
            });
    }

    onLoginSubmit(values) {
        axios
            .post('/api/v1/authenticate', values)
            .then(result => {
                console.log(result);
                if (result.data.token) {
                    this.props.onLogin(result.data.token);
                }
            })
            .catch(() => this.setState({error: "Não existe nenhum utilizador com o email e password indicados."}));
    }

    onRecoverSubmit(values) {
        axios
            .post('/api/v1/user/recover', values)
            .then(result => {
                console.log(result);
                this.setState({
                    screen: Screens.SUCCESS,
                    success: {
                        title: "Verifique o seu email",
                        message: "Por favor verifique o seu email para concluir o processo de recuperação da sua password."
                    },
                    error: '',
                })
            })
            .catch(() => this.setState({error: "O utilizador indicado não existe."}));

    }

    onChangePasswordSubmit(values) {
        axios
            .put('/api/v1/user', values, {headers: {"Authorization": `Bearer ${this.props.recoveryToken}`}})
            .then(() => {
                this.setState({
                    screen: Screens.LOGIN,
                    error: '',
                });
                window.history.replaceState({}, document.title, "/");
            })
            .catch(() => this.setState({error: "Não foi possível alterar a sua passowrd."}));
    }

    openRecoveryForm() {
        this.setState({
            screen: Screens.RECOVER,
            error: ''
        })
    }

    openLoginForm() {
        this.setState({
            screen: Screens.LOGIN,
            error: ''
        })
    }

    openSignupForm() {
        this.setState({
            screen: Screens.SIGNUP,
            error: ''
        })
    }

    render() {
        return (
            <div className={"Registration"}>
                <div className={"Form"}>
                    <Logo width={380} height={130}/>
                    <nav>
                        <button
                            className={this.state.screen !== Screens.SIGNUP ? "active" : ""}
                            onClick={this.openLoginForm}>
                            Entrar
                        </button>
                        <button
                            className={this.state.screen === Screens.SIGNUP ? "active" : ""}
                            onClick={this.openSignupForm}>
                            Registar
                        </button>
                    </nav>
                    {this.state.screen === Screens.SIGNUP &&
                    <RegistrationForm
                        onSubmit={this.onRegistrationSubmit}/>}
                    {this.state.screen === Screens.LOGIN &&
                    <LoginForm
                        onSubmit={this.onLoginSubmit}
                        openRecoveryForm={this.openRecoveryForm}/>}
                    {this.state.screen === Screens.RECOVER &&
                    <RecoverForm onSubmit={this.onRecoverSubmit}/>}
                    {this.state.screen === Screens.CHANGEPW &&
                    <ChangePasswordForm onSubmit={this.onChangePasswordSubmit}/>}
                    {this.state.screen === Screens.SUCCESS &&
                    <SuccessScreen title={this.state.success.title} message={this.state.success.message}/>}
                    {this.state.error !== '' &&
                    <div className={"error-message"}>{this.state.error}</div>}
                </div>
            </div>
        )
    }
}

let SuccessScreen = ({title, message}) => (
    <div className={"success-screen"}>
        <h1>{title}</h1>
        <em>{message}</em>
    </div>
);

export default AuthFrame;