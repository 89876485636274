import React from 'react';

export default ({version = 'black', width='100%', height='100%', className}) => {
    let textColor = "#c9b298";
    let numbersColor = version.toLowerCase() === 'white' ? "#FFFFFF" : "#23140e";
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 68" width={width} height={height}>
            <title>Aurea 219</title>
            <path className="cls-1" fill={textColor}
                  d="M26.21,1.36,0,67.36H13.66l7.52-19.2H40l7.6,19.2h13.6l-25.83-66Zm.05,34.08,4.42-11.07L35,35.44Z"/>
            <path className="cls-1" fill={textColor}
                  d="M98.29,43.72a11.67,11.67,0,0,1-1.66,6.12A12.75,12.75,0,0,1,92,54.41a11.9,11.9,0,0,1-6.18,1.67,11.64,11.64,0,0,1-6.13-1.66,12.88,12.88,0,0,1-4.58-4.58,11.66,11.66,0,0,1-1.65-6.12V1.36H60.85V43.72A25.06,25.06,0,0,0,98.43,65.37a24.9,24.9,0,0,0,9.14-9.09,24.65,24.65,0,0,0,3.36-12.56V1.36H98.29Z"/>
            <path className="cls-2" fill={numbersColor}
                  d="M151.31,26.35l.13-.23a17.55,17.55,0,0,0,1.8-7.92,18.17,18.17,0,0,0-34.53-7.95l-2.17,4.45L128,20.4l2.18-4.64a5,5,0,0,1,2-2.18,5.55,5.55,0,0,1,3-.86,5.38,5.38,0,0,1,2.75.74,5.26,5.26,0,0,1,2,2,5.49,5.49,0,0,1,.74,2.76,4.61,4.61,0,0,1-.45,2.1L113.32,67.36h43.61V54.72H135.16Z"/>
            <polygon className="cls-2" fill={numbersColor}
                     points="172.78 1.36 151.31 38.96 165.95 38.96 170.84 30.36 170.84 67.36 183.56 67.36 183.56 1.36 172.78 1.36"/>
            <path className="cls-2" fill={numbersColor}
                  d="M236.29,12.52a25.23,25.23,0,0,0-9.12-9.12A24.69,24.69,0,0,0,214.56,0,24.36,24.36,0,0,0,202,3.42a25.07,25.07,0,0,0-9,9.1,25.1,25.1,0,0,0,0,25.11A25.08,25.08,0,0,0,202,46.75a24.61,24.61,0,0,0,12.46,3.41l-9.83,17.2h14.64l8.39-14.75c4.15-7.23,7-12.19,8.31-14.73a35.23,35.23,0,0,0,2.93-6.56,23.4,23.4,0,0,0,.71-6.24A24.65,24.65,0,0,0,236.29,12.52ZM225.36,31.29a12.36,12.36,0,0,1-23.07-6.21,12,12,0,0,1,1.64-6.21,12.38,12.38,0,0,1,21.43,0A12,12,0,0,1,227,25.08,12,12,0,0,1,225.36,31.29Z"/>
        </svg>
    )
}