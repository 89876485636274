import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import CloseButton from "./CloseButton";


class PasswordChanger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            error: '',
        };
        this.escFunction = this.escFunction.bind(this);
    }
    escFunction(event){
        if(event.key === 'Escape') {
            this.props.onClose();
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        const ChangePasswordSchema = () => Yup.object().shape({
            password: Yup.string()
                .required('Campo Obrigatório')
                .min(8, 'A Password deve ter pelo menos 8 caracteres')
                .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/, 'A password deve ter pelo menos 1 maiúscula, 1 minúscula e 1 número'),
            passwordConfirmation: Yup.string()
                .required('Campo Obrigatório')
                .oneOf([Yup.ref('password'), null], 'As passwords têm de ser iguais')
        });
        return (
            <div className={"password-changer"}>
                <button
                    className={this.props.visible ? "open" : ""}
                    onClick={() => {
                        if (this.props.visible) {
                            this.props.onClose();
                        } else {
                            this.props.onOpen();
                        }
                    }}>
                    <FontAwesomeIcon icon={faLock} />
                    <div className={"label"}>Password</div>
                </button>
                {this.props.visible && !this.state.success &&
                <div className={"user-area"}>
                    <CloseButton onClick={() => this.props.onClose()} />
                    <div className="title">Password</div>
                    <em>Nesta área poderá alterar a sua password</em>
                    <Formik
                        initialValues={{
                            password: '',
                            passwordConfirmation: ''
                        }}
                        validationSchema={ChangePasswordSchema}
                        onSubmit={(values, {setSubmitting}) => {
                            axios
                                .put('/api/v1/user', values, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}})
                                .then(() => {
                                    setSubmitting(false);
                                    this.setState({
                                        success: true,
                                        error: '',
                                    });
                                })
                                .catch(() => {
                                    setSubmitting(false);
                                    this.setState({error: "Não foi possível alterar a sua passowrd."});
                                });
                        }}
                        render={(props) => {
                            const {errors, touched, handleSubmit} = props;
                            return (
                            <form onSubmit={handleSubmit}>
                                <h5 className="group-title">Dados de Login</h5>
                                <div className={"input-field"}>
                                    <Field className={touched.password ? (errors.password ? "error" : "success") : ""}
                                           type="password" name="password" id="password"/>
                                    <label htmlFor={"password"}>Password</label>
                                    {errors.password && touched.password && <span className={"error"}>{errors.password}</span>}
                                </div>
                                <div className={"input-field"}>
                                    <Field
                                        className={touched.passwordConfirmation ? (errors.passwordConfirmation ? "error" : "success") : ""}
                                        type="password" name="passwordConfirmation" id="passwordConfirmation"/>
                                    <label htmlFor={"passwordConfirmation"}>Confirmar Password</label>
                                    {errors.passwordConfirmation && touched.passwordConfirmation &&
                                    <span className={"error"}>{errors.passwordConfirmation}</span>}
                                </div>
                                <button type="submit"><span className="text">Confirmar</span></button>
                                {this.state.error !== '' &&
                                <div className={"error-message"}>{this.state.error}</div>}
                            </form>
                        )}}
                    />
                </div>}
                {this.props.visible && this.state.success &&
                <div className={"user-area success-screen"}>
                    <CloseButton onClick={() => this.props.onClose()} />
                    <div className={"title"}>Password Alterada com Sucesso!</div>
                </div>}
            </div>
        )
    }
}

export default PasswordChanger;