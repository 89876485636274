import React, {Component} from 'react';
import '../styles/App.css';
import AppFrame from "./AppFrame";
import AuthFrame from "./AuthFrame";

const Screens = Object.freeze({
    AUTH: Symbol("AUTH"),
    APP: Symbol("APP"),
});

class App extends Component {
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        if (window.location.pathname === '/resetpw') {
            let token = window.location.search.substr(7);
            this.state = {
                screen: Screens.AUTH,
                recoveryToken: token
            }
        } else {
            this.state = {
                screen: localStorage.getItem("token") ? Screens.APP : Screens.AUTH
            }
        }
    }

    logout() {
        localStorage.removeItem("token");
        this.setState({
            screen: Screens.AUTH,
            recoveryToken: null
        })
    }

    login(token) {
        localStorage.setItem("token", token);
        this.setState({
            screen: Screens.APP
        })
    }

    render() {
        return (
            <div className="App">
                {this.state.screen === Screens.AUTH &&
                <AuthFrame onLogin={this.login} recoveryToken={this.state.recoveryToken}/>}
                {this.state.screen === Screens.APP &&
                <AppFrame onLogout={this.logout}/>}
            </div>
        );
    }
}

export default App;
