import React, { Component } from "react";
import { Field, Formik } from "formik";
import {
  faChevronDown,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import * as Yup from "yup";
import * as base64js from "base64-js";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";

const Screens = Object.freeze({
  FORM: Symbol("FORM"),
  SUCCESS: Symbol("SUCCESS"),
});

const ProcessSchema = Yup.object().shape({
  description: Yup.string().required(true),
  companyCode: Yup.string().required(true),
  year: Yup.string().required(true),
  month: Yup.string().required(true),
  diary: Yup.string().required(true),
  launchType: Yup.string().required(true),
  documentType: Yup.string().required(true),
  country: Yup.string().required(true),
  taxRegion: Yup.string().required(true),
  fileType: Yup.string().required(true),
  accounts: Yup.object(),
  resultFileType: Yup.string().oneOf(["TXT", "XML"]).required(true),
  integrationMode: Yup.string(),
  computeVat: Yup.string(),
  headerDate: Yup.string(),
});

// {
//     base: {type: 'C', account: '72.1.4'},
//     vat: {type: 'C', account: '', label:"Conta do IVA"},
//     stamp: {type: 'C', account: '', label:"Conta do Imposto de Selo"},
//     irs: {type: 'C', account: '', label:"Conta do IRS"},
//     total: {type: 'D', account: '11.1', label:"Conta do Total"},
// }
const fileTypeAccounts = {
  EFR: [
    {
      name: "baseExempt",
      hasVat: false,
      label: "Conta de incidência Isenta",
      settings: { type: "-", account: "" },
    },
    {
      name: "baseReduced",
      hasVat: true,
      label: "Conta de incidência Reduzida",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "baseIntermediate",
      hasVat: true,
      label: "Conta de incidência Intermédia",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "baseNormal",
      hasVat: true,
      label: "Conta de incidência Normal",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "total",
      hasVat: false,
      label: "Conta do Total",
      settings: { type: "-", account: "" },
    },
  ],
  EFR_UPTO202301: [
    {
      name: "baseExempt",
      hasVat: false,
      label: "Conta de incidência Isenta",
      settings: { type: "-", account: "" },
    },
    {
      name: "baseReduced",
      hasVat: true,
      label: "Conta de incidência Reduzida",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "baseIntermediate",
      hasVat: true,
      label: "Conta de incidência Intermédia",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "baseNormal",
      hasVat: true,
      label: "Conta de incidência Normal",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "total",
      hasVat: false,
      label: "Conta do Total",
      settings: { type: "-", account: "" },
    },
  ],
  SIRE: [
    {
      name: "baseExempt",
      hasVat: false,
      label: "Conta de incidência Isenta",
      settings: { type: "-", account: "" },
    },
    {
      name: "baseReduced",
      hasVat: true,
      label: "Conta de incidência Reduzida",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "baseIntermediate",
      hasVat: true,
      label: "Conta de incidência Intermédia",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "baseNormal",
      hasVat: true,
      label: "Conta de incidência Normal",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "stamp",
      hasVat: false,
      label: "Conta de imposto selo",
      settings: { type: "-", account: "" },
    },
    {
      name: "irs",
      hasVat: false,
      label: "Conta de IRS",
      settings: { type: "-", account: "" },
    },
    {
      name: "total",
      hasVat: false,
      label: "Conta do Total",
      settings: { type: "-", account: "" },
    },
  ],
  UBEREATS: [
    {
      name: "baseNormal",
      hasVat: true,
      label: "Conta de incidência Normal",
      settings: { type: "-", account: "", vatAccount: "" },
    },
    {
      name: "total",
      hasVat: false,
      label: "Conta do Total",
      settings: { type: "-", account: "" },
    },
  ],
  EXCELONE: [
    {
      name: "total",
      hasVat: false,
      ignoreType: true,
      label: "Conta do Banco",
      settings: { type: "-", account: "" },
    },
  ],
  EXCELTWO: [],
  EXCELTHREE: [],
  LOCALACCOMMODATIONPLATFORM: [],
};

const exampleURLs = {
  EXCELONE: "/examples/Excel1.xlsx",
  EXCELTWO: "/examples/Excel2.xlsx",
  EXCELTHREE: "/examples/Excel3.xlsx",
};

// TODO outfiles should depend on fileType
const NONE = { value: "NONE", label: "Selecione um formato" };
const TXT_OUT = { value: "TXT", label: "Ficheiro de Texto" };
const XML_OUT = { value: "XML", label: "Ficheiro XML" };

const outFiles = {
  EFR: [NONE, TXT_OUT],
  EFR_UPTO202301: [NONE, TXT_OUT],
  SIRE: [NONE, TXT_OUT],
  UBEREATS: [NONE, TXT_OUT],
  EXCELONE: [NONE, TXT_OUT],
  EXCELTWO: [NONE, TXT_OUT],
  EXCELTHREE: [NONE, TXT_OUT],
  LOCALACCOMMODATIONPLATFORM: [NONE, XML_OUT],
};

let getAccounts = (fileType) => {
  let accounts = {};
  fileTypeAccounts[fileType].forEach((a) => {
    accounts[a.name] = JSON.parse(JSON.stringify(a.settings));
  });
  console.log(accounts);
  return accounts;
};

class FileGeneration extends Component {
  constructor(props) {
    super(props);
    this.fetchSettings = this.fetchSettings.bind(this);
    this.state = {
      screen: Screens.FORM,
      error: "",
      parametrizations: [],
    };

    this.fetchSettings();
  }

  fetchSettings() {
    axios
      .get("/api/v1/settings", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        this.setState({
          parametrizations: response.data.map((l) => {
            return { name: l };
          }),
        });
      })
      .catch((response) => console.error(response));
  }

  render() {
    return (
      <div className={"FileGeneration"}>
        {this.state.screen === Screens.FORM && (
          <Formik
            initialValues={{
              parametrization: "- Criar nova parametrização -",
              description: "",
              companyCode: "",
              year: new Date().getFullYear().toString(), //
              month: new Date().getMonth().toString(),
              diary: "",
              launchType: "",
              documentType: "",
              country: "PT",
              taxRegion: "C",
              fileType: "EFR",
              accounts: getAccounts("EFR"),
              file: "",
              resultFileType: "TXT",
              integrationMode: "S",
              headerDate: "U",
              computeVat: "no",
            }}
            onSubmit={(values, { setSubmitting }) => {
              let formData = new FormData();
              console.log(values);
              formData.append("file", values.file);
              let tempFile = values.file;
              values.file = null;
              formData.append("settings", JSON.stringify(values));
              values.file = tempFile;

              axios
                .post("/api/v1/process", formData, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                })
                .then((response) => {
                  let blob = new Blob([
                    base64js.toByteArray(response.data.data),
                  ]);
                  let fileName = response.data.filename;

                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                  } else {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                  }

                  this.setState({ screen: Screens.SUCCESS, error: "" });
                  this.props.onGenerationSuccess();
                  setSubmitting(false);
                })
                .catch((err) => {
                  console.log(err.response);
                  this.setState({ error: err.response.data });
                  setSubmitting(false);
                });
            }}
            validationSchema={ProcessSchema}
            render={({
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              values,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <h1>Parametrização</h1>

                <div className="input-group">
                  <ParamSelectCustomField
                    label={"Parametrização"}
                    name={"parametrization"}
                    touched={touched}
                    errors={errors}
                    onChange={(e) => {
                      let newValue = e.currentTarget.value;
                      setFieldValue("parametrization", newValue);
                      console.log(newValue);
                      if (newValue !== "- Criar nova parametrização -") {
                        axios
                          .get(`/api/v1/settings/${newValue}`, {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                          })
                          .then((response) => {
                            console.log(response.data);
                            setFieldValue("accounts", response.data.accounts);
                            setFieldValue("fileType", response.data.fileType);
                            setFieldValue(
                              "resultFileType",
                              response.data.resultFileType ?? "TXT",
                            );
                            setFieldValue(
                              "companyCode",
                              response.data.companyCode,
                            );
                            setFieldValue("country", response.data.country);
                            setFieldValue(
                              "description",
                              response.data.description,
                            );
                            setFieldValue("diary", response.data.diary);
                            setFieldValue(
                              "documentType",
                              response.data.documentType,
                            );
                            setFieldValue(
                              "launchType",
                              response.data.launchType,
                            );
                            setFieldValue("month", response.data.month);
                            setFieldValue("taxRegion", response.data.taxRegion);
                            setFieldValue("year", response.data.year);
                            setFieldValue(
                              "integrationMode",
                              response.data.integrationMode
                                ? response.data.integrationMode
                                : "S",
                            );
                            setFieldValue(
                              "headerDate",
                              response.data.headerDate
                                ? response.data.headerDate
                                : "U",
                            );
                            setFieldValue(
                              "computeVat",
                              response.data.computeVat
                                ? response.data.computeVat
                                : "no",
                            );
                          })
                          .catch((response) => console.error(response));
                        setFieldValue("description", newValue);
                      }
                    }}
                    value={values.parametrization}
                    options={this.state.parametrizations
                      .map((p) => p.name)
                      .concat(["- Criar nova parametrização -"])
                      .map((p) => {
                        return { value: p, label: p };
                      })}
                  />
                  <ParamInputField
                    disabled={
                      values.parametrization !== "- Criar nova parametrização -"
                    }
                    label={"Descrição"}
                    placeholder={"Ex. DEMO - SIRE"}
                    name={"description"}
                    type={"text"}
                    touched={touched}
                    errors={errors}
                  />
                  <ParamInputField
                    label={"Código da Empresa"}
                    name={"companyCode"}
                    type={"text"}
                    placeholder={"Ex. DEMO"}
                    touched={touched}
                    errors={errors}
                  />
                  <ParamInputField
                    label={"Ano de Lançamento"}
                    name={"year"}
                    type={"text"}
                    touched={touched}
                    errors={errors}
                  />
                  <ParamSelectField
                    label={"Mês de Lançamento"}
                    name={"month"}
                    touched={touched}
                    errors={errors}
                    options={[
                      { value: "1", label: "Janeiro" },
                      { value: "2", label: "Fevereiro" },
                      { value: "3", label: "Março" },
                      { value: "4", label: "Abril" },
                      { value: "5", label: "Maio" },
                      { value: "6", label: "Junho" },
                      { value: "7", label: "Julho" },
                      { value: "8", label: "Agosto" },
                      { value: "9", label: "Setembro" },
                      { value: "10", label: "Outubro" },
                      { value: "11", label: "Novembro" },
                      { value: "12", label: "Dezembro" },
                    ]}
                  />
                  <ParamInputField
                    label={"Diário de Lançamento"}
                    name={"diary"}
                    type={"text"}
                    placeholder={"Ex. 2"}
                    touched={touched}
                    errors={errors}
                  />
                  <ParamInputField
                    label={"Tipo de Lançamento"}
                    name={"launchType"}
                    type={"text"}
                    placeholder={"Ex. PServicos"}
                    touched={touched}
                    errors={errors}
                  />
                  <ParamInputField
                    label={"Tipo de Documento"}
                    name={"documentType"}
                    type={"text"}
                    placeholder={"Ex. 2"}
                    touched={touched}
                    errors={errors}
                  />

                  <ParamSelectField
                    label={"País"}
                    name={"country"}
                    touched={touched}
                    errors={errors}
                    options={[{ value: "PT", label: "Portugal" }]}
                  />
                  <ParamSelectField
                    label={"Espaço Fiscal"}
                    name={"taxRegion"}
                    touched={touched}
                    errors={errors}
                    options={[
                      { value: "C", label: "Continente" },
                      { value: "A", label: "Açores" },
                      { value: "M", label: "Madeira" },
                    ]}
                  />
                </div>
                <div className="input-group">
                  <ParamSelectCustomField
                    label={"Tipo de Ficheiro de Origem"}
                    name={"fileType"}
                    touched={touched}
                    errors={errors}
                    onChange={(e) => {
                      let newValue = e.currentTarget.value;
                      setFieldValue("accounts", getAccounts(newValue));
                      setFieldValue("fileType", newValue);
                    }}
                    options={[
                      {
                        value: "EFR",
                        label:
                          "eFatura - Receitas (Novo formato a partir de 01/2023)",
                      },
                      {
                        value: "EFR_UPTO202301",
                        label:
                          "eFatura - Receitas (Formato disponível entre 10/2022 e 01/2023)",
                      },
                      {
                        value: "SIRE",
                        label: "SIRE (Novo formato a partir de 10/2022)",
                      },
                      {
                        value: "EXCELONE",
                        label: "Formato Personalizado Excel - 1",
                      },
                      {
                        value: "EXCELTWO",
                        label: "Formato Personalizado Excel - 2",
                      },
                      {
                        value: "EXCELTHREE",
                        label: "Formato Personalizado Excel - 3",
                      },
                      {
                        value: "UBEREATS",
                        label: "Formato Uber Eats Portugal",
                      },
                      {
                        value: "LOCALACCOMMODATIONPLATFORM",
                        label: "Formato Plataformas de Alojamento Local",
                      },
                    ]}
                  />
                  {exampleURLs[values.fileType] && (
                    <div className={"download-sample-button-container"}>
                      <span className={"fill"} />
                      <a
                        href={exampleURLs[values.fileType]}
                        className={"download-sample-button"}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        Transferir Exemplo
                      </a>
                    </div>
                  )}
                  <ParamSelectCustomField
                    label={"Tipo de Ficheiro de Importação"}
                    name={"resultFileType"}
                    touched={touched}
                    errors={errors}
                    onChange={(e) => {
                      let newValue = e.currentTarget.value;
                      setFieldValue("resultFileType", newValue);
                    }}
                    options={outFiles[values.fileType]}
                  />
                  {fileTypeAccounts[values.fileType].map((a) => (
                    <ParamAccountField
                      key={a.name}
                      label={a.label}
                      name={`accounts.${a.name}`}
                      hasVat={a.hasVat}
                      ignoreType={a.ignoreType}
                      touched={touched}
                      errors={errors}
                    />
                  ))}
                </div>
                {(values.fileType === "EXCELONE" ||
                  values.fileType === "EXCELTWO") && (
                  <ParamSelectField
                    label={"Modo de Integração"}
                    name={"integrationMode"}
                    touched={touched}
                    errors={errors}
                    options={[
                      {
                        value: "S",
                        label: "Cada linha num lançamento separado",
                      },
                      {
                        value: "J",
                        label: "Juntar todas as linhas no mesmo lançamento",
                      },
                    ]}
                  />
                )}
                {values.integrationMode !== "J" &&
                  values.fileType !== "EXCELTHREE" && (
                    <ParamSelectField
                      label={"Data do Cabeçalho"}
                      name={"headerDate"}
                      touched={touched}
                      errors={errors}
                      options={[
                        { value: "U", label: "Último dia do mês" },
                        { value: "D", label: "Data do Documento" },
                      ]}
                    />
                  )}
                {values.fileType === "EXCELTHREE" && (
                  <ParamSelectField
                    label={"Data do Cabeçalho"}
                    name={"headerDate"}
                    touched={touched}
                    errors={errors}
                    options={[
                      { value: "U", label: "Último dia do mês" },
                      { value: "D", label: "Data do Documento" },
                    ]}
                  />
                )}
                {values.fileType === "UBEREATS" && (
                  <ParamSelectField
                    label={"IVA"}
                    name={"computeVAT"}
                    touched={touched}
                    errors={errors}
                    options={[
                      { value: "no", label: "Importar indicado no ficheiro." },
                      {
                        value: "if0",
                        label: "Calcular a 23% se no ficheiro estiver 0%.",
                      },
                    ]}
                  />
                )}
                <div className="input-group">
                  <div className="file-field">
                    <span className={"fill"} />
                    <button
                      type={"button"}
                      className={values.file ? "success" : ""}
                    >
                      <FontAwesomeIcon
                        icon={values.file ? faCheck : faUpload}
                      />
                      {values.file ? "Ficheiro Carregado" : "Carregar Ficheiro"}
                    </button>
                    {/*<Field id="file" name="file" type="file" required={true} accept=".csv"*/}
                    <input
                      id="file"
                      name="file"
                      type="file"
                      required={true}
                      accept={computeAcceptedFileTypes(values.fileType)}
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                    />
                  </div>
                </div>
                <button type="submit" disabled={isSubmitting}>
                  Gerar Ficheiro
                </button>
                {this.state.error !== "" && (
                  <div className={"error-message"}>{this.state.error}</div>
                )}
              </form>
            )}
          />
        )}
        {this.state.screen === Screens.SUCCESS && (
          <div className={"success-screen"}>
            <h1 className={"screen-title"}>Ficheiro Gerado com Sucesso</h1>
            <em>O seu ficheiro de integração à contabilidade está pronto!</em>
            {/*<em>Caso não tenha sido descarregado automaticamente CLIQUE AQUI.</em>*/}
            <br />
            <button
              className={"default"}
              onClick={() => {
                this.fetchSettings();
                this.setState({ screen: Screens.FORM });
              }}
            >
              Início
            </button>
          </div>
        )}
      </div>
    );
  }
}

let computeAcceptedFileTypes = (fileType) => {
  if (fileType === "EFR" || fileType === "SIRE") {
    return ".csv";
  } else if (
    fileType === "EXCELONE" ||
    fileType === "EXCELTWO" ||
    fileType === "EXCELTHREE"
  ) {
    return "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  } else {
    return "*";
  }
};

let ParamInputField = ({
  label,
  name,
  type,
  touched,
  errors,
  disabled,
  placeholder,
}) => (
  <div className={"input-field"}>
    <label htmlFor={name}>{label}</label>
    <Field
      className={
        touched[name]
          ? errors[name]
            ? "error input"
            : "success input"
          : "input"
      }
      placeholder={placeholder}
      type={type}
      name={name}
      id={name}
      disabled={disabled}
    />
    {errors[name] && touched[name] && (
      <span className={"error"}>{errors[name]}</span>
    )}
  </div>
);

let ParamAccountField = ({
  label,
  name,
  touched,
  errors,
  hasVat,
  ignoreType,
}) => (
  <div className={"input-field transparent"}>
    <label htmlFor={`${name}.account`}>{label}</label>
    <div className="input">
      {!ignoreType && (
        <div className="select">
          <Field component="select" name={`${name}.type`}>
            <option disabled value="-">
              Natureza
            </option>
            <option value="C">Crédito</option>
            <option value="D">Débito</option>
          </Field>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )}
      <Field
        style={{ width: hasVat ? "50%" : undefined }}
        placeholder="Ex. 11.1"
        className={
          touched[`${name}.account`]
            ? errors[`${name}.account`]
              ? "error"
              : "success"
            : ""
        }
        type={"text"}
        name={`${name}.account`}
        id={`${name}.account`}
      />
      {hasVat && (
        <label style={{ background: "#f2f2f2" }} htmlFor={`${name}.vatAccount`}>
          Conta do IVA
        </label>
      )}
      {hasVat && (
        <Field
          placeholder="Ex. 11.1"
          style={{ width: "50%" }}
          className={
            touched[`${name}.account`]
              ? errors[`${name}.account`]
                ? "error"
                : "success"
              : ""
          }
          type={"text"}
          name={`${name}.vatAccount`}
          id={`${name}.vatAccount`}
        />
      )}
      {errors[`${name}.account`] && touched[`${name}.account`] && (
        <span className={"error"}>{errors[`${name}.account`]}</span>
      )}
    </div>
  </div>
);

let ParamSelectField = ({ label, name, touched, errors, options }) => (
  <div className={"input-field"}>
    <label htmlFor={name}>{label}</label>
    <div
      className={
        touched[name]
          ? errors[name]
            ? "select error input"
            : "select success input"
          : "input select"
      }
    >
      <Field component="select" name={name}>
        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </Field>
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  </div>
);

let ParamSelectCustomField = ({
  label,
  name,
  touched,
  errors,
  options,
  onChange,
}) => (
  <div className={"input-field"}>
    <label htmlFor={name}>{label}</label>
    <div
      className={
        touched[name]
          ? errors[name]
            ? "input select error"
            : "input select success"
          : "input select"
      }
    >
      <Field onChange={onChange} component="select" name={name}>
        {options?.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </Field>
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  </div>
);

export default FileGeneration;
