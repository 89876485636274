import React, {Component} from 'react'
import Logo from "../svg/Logo";
import '../styles/AppFrame.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import UsageInfo from "./UsageInfo";
import FileGeneration from "./FileGeneration";
import WalletCharger from "./WalletCharger";
import PasswordChanger from "./PasswordChanger";
import axios from 'axios';

const Menus = Object.freeze({
    WALLET: Symbol("WALLET"),
    PASSWORD: Symbol("PASSWORD"),
    NONE: Symbol(""),
});

class AppFrame extends Component {
    constructor(props) {
        super(props);
        this.updateWallet = this.updateWallet.bind(this);
        this.state = {
            wallet: {
                total: 0,
                used: 0,
            },
            open: ""
        }
    }

    componentDidMount() {
        this.updateWallet();
    }

    updateWallet() {
        axios
            .get('/api/v1/wallet', {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}})
            .then(result => this.setState({wallet: result.data}))
            .catch(err => {
                if (err.response.status === 401 || err.response.status === 403) {
                    this.props.onLogout();
                }
            })
    }

    render() {
        return (
            <div className={"Frame"}>
                <div className="Sidebar">
                    <div className="top">
                        <Logo className={"logo"} version={"white"} width={60} height={80}/>
                        <UsageInfo used={this.state.wallet.used} purchased={this.state.wallet.total}/>
                        <WalletCharger
                            visible={this.state.open === Menus.WALLET}
                            wallet={this.state.wallet}
                            updateWallet={this.updateWallet}
                            onOpen={() => this.setState({open: Menus.WALLET})}
                            onClose={() => this.setState({open: Menus.NONE})}/>
                    </div>
                    <div className="bottom">
                        <PasswordChanger
                            visible={this.state.open === Menus.PASSWORD}
                            onOpen={() => this.setState({open: Menus.PASSWORD})}
                            onClose={() => this.setState({open: Menus.NONE})}/>
                        <button onClick={this.props.onLogout} className="logout">
                            <FontAwesomeIcon icon={faPowerOff}/>
                            <div className={"label"}>Sair</div>
                        </button>
                    </div>
                </div>
                <div className="Main">
                    <FileGeneration onGenerationSuccess={this.updateWallet}/>
                </div>
            </div>
        )
    }
}

export default AppFrame
