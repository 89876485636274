import React from 'react'
import {Field, Formik} from "formik";
import * as Yup from 'yup';

export default ({onSubmit}) => {
    const RecoverSchema = () => Yup.object().shape({
        password: Yup.string()
            .required('Campo Obrigatório')
            .min(8, 'A Password deve ter pelo menos 8 caracteres')
            .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/, 'A password deve ter pelo menos 1 maiúscula, 1 minúscula e 1 número'),
        passwordConfirmation: Yup.string()
            .required('Campo Obrigatório')
            .oneOf([Yup.ref('password'), null], 'As passwords têm de ser iguais'),
    });
    return (
        <Formik
            initialValues={{
                password: '',
                passwordConfirmation: '',
            }}
            validationSchema={RecoverSchema}
            onSubmit={(values, {setSubmitting}) => {
                onSubmit(values);
                setTimeout(() => {
                    setSubmitting(false);
                }, 1000);
            }}
            render={({errors, touched, handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                    <em>Introduza a sua nova password.</em>
                    <div className={"input-field"}>
                        <Field className={touched.password ? (errors.password ? "error" : "success") : ""}
                               type="password" name="password" id="password"/>
                        <label htmlFor={"password"}>Password</label>
                        {errors.password && touched.password && <span className={"error"}>{errors.password}</span>}
                    </div>
                    <div className={"input-field"}>
                        <Field
                            className={touched.passwordConfirmation ? (errors.passwordConfirmation ? "error" : "success") : ""}
                            type="password" name="passwordConfirmation" id="passwordConfirmation"/>
                        <label htmlFor={"passwordConfirmation"}>Confirmar Password</label>
                        {errors.passwordConfirmation && touched.passwordConfirmation &&
                        <span className={"error"}>{errors.passwordConfirmation}</span>}
                    </div>
                    <button type="submit">Alterar Password</button>
                </form>
            )}
        />
    )
}