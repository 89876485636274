import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWallet} from "@fortawesome/free-solid-svg-icons";
import {Field, Formik} from "formik";
import {PrintMoney} from "./util";
import axios from 'axios';
import CloseButton from "./CloseButton";


class WalletCharger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            error: '',
        }
    }
    escFunction(event){
        if(event.key === 'Escape') {
            this.props.onClose();
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        let unitPrice = 2.0;
        return (
            <div className={"wallet-charger"}>
                <button
                    className={this.props.visible ? "open" : ""}
                    onClick={() => {
                        if (this.props.visible) {
                            this.props.onClose();
                        } else {
                            this.props.onOpen();
                        }                    }}>
                    <FontAwesomeIcon icon={faWallet} />
                    <div className={"label"}>Carteira</div>
                </button>
                {this.props.visible && !(this.props.wallet.waitingPayment !== 0) && !this.state.success  &&
                <div className={"user-area"}>
                    <CloseButton onClick={() => this.props.onClose()} />
                    <div className="title">A Minha Carteira</div>
                    <em>Nesta área poderá carregar a sua carteira para que possa gerar mais ficheiros</em>
                    <Formik
                        initialValues={{
                            amount: 10,
                        }}
                        isInitialValid={true}
                        validate={(values) => {
                            if (values.amount < 10 || values.amount % 10 !== 0) {
                                return {amount: 'Tem de ser múltiplo de 10.'}
                            }
                            return {}
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            axios
                                .post('/api/v1/wallet/charge', values, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}})
                                .then(() => {
                                    this.setState({
                                        success: true,
                                        error: '',
                                    });
                                    setSubmitting(false);
                                    this.props.updateWallet();
                                })
                                .catch(() => {
                                    setSubmitting(false);
                                    this.setState({error: "Não foi possível concluir o carregamento da carteira, por favor tente mais tarde."})
                                });
                        }}
                        render={(props) => {
                            const {errors, touched, handleSubmit, values, setFieldValue, isValid} = props;
                            console.log(isValid);
                            return (
                                <form onSubmit={handleSubmit}>
                                    <h5 className="group-title">Escolha um dos pacotes</h5>
                                    <CreditPacks setFieldValue={setFieldValue} currentAmount={values.amount} />
                                    <div className={"input-row"}>
                                        <label
                                            className={"input-label" + (touched.amount ? (errors.amount ? " error" : " success") : "")}
                                            htmlFor={"amount"}>Nº de Créditos</label>
                                        <div className={"input-field"}>
                                            <Field className={touched.amount ? (errors.amount ? "error" : "success") : ""}
                                                   type="number" min={10} step={10} name="amount" id="amount"/>
                                            {errors.amount && touched.amount &&
                                            <span className={"error"}>{errors.amount}</span>}
                                        </div>
                                    </div>
                                    <div className="" style={{textAlign: 'left'}}>
                                        Receberá gratuitamente mais <strong>{computeBonus(values.amount)}</strong> crédito{computeBonus(values.amount) === 1 ? '' : 's'}.
                                    </div>
                                    <div className={"total-line"}>
                                        Valor a pagar <span className={"price"}>{PrintMoney(values.amount * unitPrice * 1.23, 2)}</span>
                                    </div>
                                    <button type="submit" disabled={!isValid}><span className="text">Carregar</span></button>
                                    {this.state.error !== '' &&
                                    <div className={"error-message"}>{this.state.error}</div>}
                                </form>
                            )}
                        }
                    />
                </div>}
                {this.props.visible && (this.props.wallet.waitingPayment !== 0 || this.state.success) &&
                <div className={"user-area success-screen"}>
                    <CloseButton onClick={() => this.props.onClose()} />
                    {!this.props.wallet.paymentInfo
                      ? (
                        <>
                          <div className={"title"}>Atualize a página</div>
                          <em>Por favor, atualize a página para ver os dados de pagamento.</em>
                        </>
                      ) : (
                        <>
                          <div className={"title"}>Dados de Carregamento</div>
                          <div>{this.props.wallet.paymentInfo.creditos} créditos {this.props.wallet.paymentInfo.creditos >= 50 ? <span style={{color: '#816450', fontWeight: 'bold'}}> + {computeBonus(this.props.wallet.paymentInfo.creditos)} grátis</span> : ''}</div>
                          <div className={"reference"}>
                            <img className={"logo"} alt="Multibanco" src={"https://aurea219.pt/static/img/Multibanco.png"}/>
                            <div className={"info"}>
                              <div><span>Entidade:</span> <strong>{this.props.wallet.paymentInfo.entidade}</strong></div>
                              <div><span>Referência:</span> <strong>{this.props.wallet.paymentInfo.referencia.match(/.{1,3}/g).join(" ")}</strong></div>
                              <div><span>Valor:</span> <strong>{parseFloat(this.props.wallet.paymentInfo.valor).toFixed(2)} €</strong></div>
                            </div>
                          </div>
                        </>
                      )}
                    <em>Assim que processarmos o seu pagamento será adicionado à sua carteira o plafond de ficheiros a gerar pelo Aurea 219.</em>
                </div>}
            </div>
        )
    }
}

function CreditPacks({currentAmount, setFieldValue}) {
    return <div className="options">
        <CreditPackCard onClick={() => setFieldValue('amount', 10)} amount={10} active={currentAmount === 10} />
        <CreditPackCard onClick={() => setFieldValue('amount', 50)} amount={50} active={currentAmount === 50} />
        <CreditPackCard onClick={() => setFieldValue('amount', 100)} amount={100} active={currentAmount === 100} />
<style jsx>{`
.options {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
`}</style>
    </div>
}

function CreditPackCard({amount, active, onClick}) {
    const bonus = computeBonus(amount);
    const bonusPercentage = computeBonusPercentage(amount);
    return <div onClick={onClick} className={["container"].concat(active ? "active" : []).join(" ")}>
        <div className="amount">{amount} créditos</div>
        {bonus > 0 && <div className="bonus"><small>+{bonusPercentage} grátis</small></div>}
<style jsx>{`
.container {
    position: relative;
    background: #553928;
    color: white;
    margin: 6px;
    padding: 8px;
    width: 128px;
    cursor: pointer;
    border: 6px solid transparent;
    border-radius: 4px;
}
.container:first-of-type {
    margin-left: 0;
}
.container:last-of-type {
    margin-right: 0;
}
.container:hover {
    background: rgba(85, 57, 40, 0.9);
}
.container.active {
    border-color: #86A0AE;
    background: #4e6365;
}
.amount {
    font-size: 16px;
    font-weight: 700;
}
`}</style>
    </div>
}

function computeBonus(amount) {
    if (amount >= 100) {
        return Math.floor(amount / 5);
    }
    if (amount >= 50 && amount < 100) {
        return Math.floor(amount / 10);
    }
    return 0;
}
function computeBonusPercentage(amount) {
    if (amount >= 100) {
        return '20%';
    }
    if (amount >= 50 && amount < 100) {
        return '10%';
    }
    return '0%';
}

export default WalletCharger;
