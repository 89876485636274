import React from 'react'
import {Field, Formik} from "formik";
import * as Yup from 'yup';

export default ({onSubmit, openRecoveryForm}) => {
    const LoginSchema = () => Yup.object().shape({
        email: Yup.string()
            .required('Campo Obrigatório'),
        password: Yup.string()
            .required('Campo Obrigatório')
    });
    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, {setSubmitting}) => {
                onSubmit(values);
                setTimeout(() => {
                    setSubmitting(false);
                }, 1000);
            }}
            render={({errors, touched, handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                    <div className={"input-field"}>
                        <Field className={touched.email ? (errors.email ? "error" : "success") : ""} type="email"
                               name="email" id="email"/>
                        <label htmlFor={"email"}>Email</label>
                        {errors.email && touched.email && <span className={"error"}>{errors.email}</span>}
                    </div>
                    <div className={"input-field"}>
                        <Field className={touched.password ? (errors.password ? "error" : "success") : ""}
                               type="password" name="password" id="password"/>
                        <label htmlFor={"password"}>Password</label>
                        {errors.password && touched.password && <span className={"error"}>{errors.password}</span>}
                    </div>
                    <button type="submit">Entrar</button>
                    <button type={"button"} className="small flat" onClick={e => {
                        e.preventDefault();
                        openRecoveryForm()
                    }}>Esqueceu-se da Password?
                    </button>
                </form>
            )}
        />
    )
}