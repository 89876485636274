let PrintMoney = (amount, decimalCount = 2, decimal = ",", thousands = " ") => {
    return printValue(amount, decimalCount, decimal, thousands) + "€"
};

let printValue = (amount, decimalCount = 0, decimal = ",", thousands = " ") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount));
        let is = i.toString();
        let j = (is.length > 3) ? is.length % 3 : 0;

        return negativeSign + (j ? is.substr(0, j) + thousands : '') + is.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
    return ''
};

/**
 * @return {boolean}
 */
function CheckNIF(value) {
    if (!value) return false;
    const nif = typeof value === 'string' ? value : value.toString();
    const validationSets = {
        one: ['1', '2', '3', '5', '6', '8'],
        two: ['45', '70', '71', '72', '74', '75', '77', '79', '90', '91', '98', '99']
    };

    if (nif.length !== 9) {
        return false;
    }

    if (validationSets.one.indexOf(nif.substr(0, 1)) < 0 && validationSets.two.indexOf(nif.substr(0, 2)) < 0) {
        return false;
    }

    let total = nif[0] * 9 + nif[1] * 8 + nif[2] * 7 + nif[3] * 6 + nif[4] * 5 + nif[5] * 4 + nif[6] * 3 + nif[7] * 2;
    let modulo11 = (Number(total) % 11);

    const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;

    return checkDigit === Number(nif[8]);
}
export {
    PrintMoney,
    CheckNIF
};