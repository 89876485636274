import React from 'react'
import {Field, Formik} from "formik";
import * as Yup from 'yup';
import {CheckNIF} from "./util";

export default ({onSubmit}) => {
    //let unitPrice = 2;
    const SignupSchema = () => Yup.object().shape({
        email: Yup.string()
            .email('Endereço de email inválido')
            .required('Campo Obrigatório'),
        password: Yup.string()
            .required('Campo Obrigatório')
            .min(8, 'A Password deve ter pelo menos 8 caracteres')
            .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/, 'A password deve ter pelo menos 1 maiúscula, 1 minúscula e 1 número'),
        passwordConfirmation: Yup.string()
            .required('Campo Obrigatório')
            .oneOf([Yup.ref('password'), null], 'As passwords têm de ser iguais'),
        taxID: Yup.string()
            .required('Campo Obrigatório')
            .test('taxID', 'O número de contribuinte indicado é inválido', CheckNIF),
        companyName: Yup.string()
            .required('Campo Obrigatório'),
        address: Yup.object().shape({
            street: Yup.string()
                .required('Campo Obrigatório'),
            zipCode: Yup.string()
                .required('Campo Obrigatório')
                .matches(/^\d{4}(-\d{3})?$/, 'O código postal é inválido'),
            city: Yup.string()
                .required('Campo Obrigatório')
        }),
        accountantName: Yup.string()
            .required('Campo Obrigatório'),
        accountantNumber: Yup.string()
            .required('Campo Obrigatório'),
        // orderSize: Yup.number()
        //     .required('Campo Obrigatório')
        //     .min(10, 'Tem de comprar pelo menos 10 gerações'),
        acceptsConditions: Yup.boolean().required('Campo Obrigatório').oneOf([true], 'Deve aceitar os termos e condições'),
        acceptsCommunications: Yup.boolean(),
    });
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                passwordConfirmation: '',
                accountantName: '',
                accountantNumber: '',
                taxID: '',
                companyName: '',
                address: {
                    street: '',
                    zipCode: '',
                    city: '',
                },
                // orderSize: 10,
                acceptsConditions: false,
                acceptsCommunications: false,
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, {setSubmitting}) => {
                onSubmit(values, setSubmitting);
            }}
            render={({errors, touched, handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                    <h5 className="group-title">Dados de Login</h5>
                    <div className={"input-field"}>
                        <Field className={touched.email ? (errors.email ? "error" : "success") : ""} type="email"
                               name="email" id="email"/>
                        <label htmlFor={"email"}>Email</label>
                        {errors.email && touched.email && <span className={"error"}>{errors.email}</span>}
                    </div>
                    <div className={"input-field"}>
                        <Field className={touched.password ? (errors.password ? "error" : "success") : ""}
                               type="password" name="password" id="password"/>
                        <label htmlFor={"password"}>Password</label>
                        {errors.password && touched.password && <span className={"error"}>{errors.password}</span>}
                    </div>
                    <div className={"input-field"}>
                        <Field
                            className={touched.passwordConfirmation ? (errors.passwordConfirmation ? "error" : "success") : ""}
                            type="password" name="passwordConfirmation" id="passwordConfirmation"/>
                        <label htmlFor={"passwordConfirmation"}>Confirmar Password</label>
                        {errors.passwordConfirmation && touched.passwordConfirmation &&
                        <span className={"error"}>{errors.passwordConfirmation}</span>}
                    </div>
                    <InputField errors={errors} touched={touched} type={"text"}
                                name={"accountantName"}
                                label={"Nome do Contabilista Certificado"} />
                    <InputField errors={errors} touched={touched} type={"text"}
                                name={"accountantNumber"}
                                label={"Número de Contabilista Certificado"} />

                    <h5 className="group-title">Dados de Faturação</h5>
                    <div className={"input-field"}>
                        <Field className={touched.companyName ? (errors.companyName ? "error" : "success") : ""}
                               type="text" name="companyName" id="companyName"/>
                        <label htmlFor={"companyName"}>Nome da Empresa</label>
                        {errors.companyName && touched.companyName &&
                        <span className={"error"}>{errors.companyName}</span>}
                    </div>
                    <div className={"input-field"}>
                        <Field
                            className={touched.address && touched.address.street ? (errors.address && errors.address.street ? "error" : "success") : ""}
                            type="text" name="address.street" id="address.street"/>
                        <label htmlFor={"address.street"}>Rua</label>
                        {errors.address && errors.address.street && touched.address && touched.address.street &&
                        <span className={"error"}>{errors.address.street}</span>}
                    </div>
                    <div className={"input-row"}>
                        <div className={"input-field"}>
                            <Field
                                className={touched.address && touched.address.zipCode ? (errors.address && errors.address.zipCode ? "error" : "success") : ""}
                                type="text" name="address.zipCode" id="address.zipCode"/>
                            <label htmlFor={"address.zipCode"}>Código Postal</label>
                            {errors.address && errors.address.zipCode && touched.address && touched.address.zipCode &&
                            <span className={"error"}>{errors.address.zipCode}</span>}
                        </div>
                        <div className={"input-field"}>
                            <Field
                                className={touched.address && touched.address.city ? (errors.address && errors.address.city ? "error" : "success") : ""}
                                type="text" name="address.city" id="address.city"/>
                            <label htmlFor={"address.city"}>Localidade</label>
                            {errors.address && errors.address.city && touched.address && touched.address.city &&
                            <span className={"error"}>{errors.address.city}</span>}
                        </div>
                    </div>
                    <div className={"input-field"}>
                        <Field className={touched.taxID ? (errors.taxID ? "error" : "success") : ""} type="text"
                               name="taxID" id="taxID"/>
                        <label htmlFor={"taxID"}>Número de Contribuinte</label>
                        {errors.taxID && touched.taxID && <span className={"error"}>{errors.taxID}</span>}
                    </div>

                    {/*<h5 className="group-title">Dados do Serviço</h5>*/}
                    {/*<div className={"input-row"}>*/}
                        {/*<label*/}
                            {/*className={"input-label" + (touched.orderSize ? (errors.orderSize ? " error" : " success") : "")}*/}
                            {/*htmlFor={"orderSize"}>Nº de Ficheiros a Gerar</label>*/}
                        {/*<div className={"input-field"}>*/}
                            {/*<Field className={touched.orderSize ? (errors.orderSize ? "error" : "success") : ""}*/}
                                   {/*type="number" min={10} name="orderSize" id="orderSize"/>*/}
                            {/*{errors.orderSize && touched.orderSize &&*/}
                            {/*<span className={"error"}>{errors.orderSize}</span>}*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className={"total-line"}>*/}
                        {/*Valor a pagar <span className={"price"}>{PrintMoney(values.orderSize * unitPrice * 1.23, 2)}</span>*/}
                    {/*</div>*/}
                    <div className={"checkbox-field"}>
                        <Field
                            className={errors.acceptsConditions && touched.acceptsConditions ? "error styled-checkbox" : "styled-checkbox"}
                            type="checkbox" name="acceptsConditions" id="acceptsConditions"/>
                        <label htmlFor="acceptsConditions">Confirmo que li e aceito os <a target={"_blank"} rel="noopener noreferrer" href="https://aurea219.pt/politica-de-privacidade/">TERMOS E CONDIÇÕES DE UTILIZAÇÃO
                            do AUREA 219</a></label>
                        {errors.acceptsConditions && touched.acceptsConditions &&
                        <div className={"error"}>{errors.acceptsConditions}</div>}
                    </div>
                    <div className={"checkbox-field"}>
                        <Field
                            className={errors.acceptsCommunications && touched.acceptsCommunications ? "error styled-checkbox" : "styled-checkbox"}
                            type="checkbox" name="acceptsCommunications" id="acceptsCommunications"/>
                        <label htmlFor="acceptsCommunications">Desejo receber notificações sobre as soluções
                            desenvolvidas pela Labor Team</label>
                        {errors.acceptsCommunications && touched.acceptsCommunications &&
                        <div className={"error"}>{errors.acceptsCommunications}</div>}
                    </div>
                    <button type="submit">Confirmar</button>
                </form>
            )}
        />
    )
}

let InputField = ({touched, errors, name, label, type}) => (
    <div className={"input-field"}>
        <Field className={touched[name] ? (errors[name] ? "error" : "success") : ""} type={type}
               name={name} id={name}/>
        <label htmlFor={name}>{label}</label>
        {errors[name] && touched[name] && <span className={"error"}>{errors[name]}</span>}
    </div>
);
